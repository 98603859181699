import React from 'react';
import { NavBar } from './Navbar';
import { Footer } from './Footer';
import { Loader
 } from '@aws-amplify/ui-react';
import { defaultMessage } from '../utils/constants';
export const Unlogged = () => (
    <div>
        <NavBar username={defaultMessage} />

        <Loader width="10rem" height="10rem" />
        <Footer/>

      </div>
  );