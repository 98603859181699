import awsExports from '../aws-exports';
import {Auth} from '@aws-amplify/auth';
import {API_ENDPOINT_URL, API_GATEWAY_RESOURCE, AWS_COGNITO_POOL_AUTH_TYPE,
    AWS_REGION, DEMO_API_REGION, DEMO_ERROR_API_NAME, DEMO_ERROR_API_URL, REST, VIPER_API_GATEWAY, VIPER_API_LIST_STACK_URL } from './constants';
 

export const getAWSExportConfig = () => {


/**
   Redirect Sign-in & Sign-out to appropriate origin
 */

if (typeof window !== "undefined") {
    awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
    awsExports.oauth.redirectSignOut = `${window.location.origin}/`;
    console.log(awsExports.oauth.redirectSignIn);
    /**
         Deleting this property, because the external API to Amplify app, were creating a lot of problems to set up
         Deleting the aws_cloud_logic_custom property. 
    */
    delete awsExports.aws_cloud_logic_custom;
  
  }

  let getBranchName = awsExports.oauth.domain.replace('.auth.us-west-2.amazoncognito.com', '');
  let branchName = getBranchName.includes('prod') ? 'adpclient' : getBranchName;

/**
 *  Registering Logging / API Gateway i=in the config object
 */

  let extendedConfig = {
    ...awsExports,
    Logging: {
      logGroupName: 'adpnawsclientlog',
      logStreamName: branchName,
    },
    API: {
      endpoints: [
        {
          endpointType: REST,
          name: DEMO_ERROR_API_NAME,
          endpoint: DEMO_ERROR_API_URL,
          region: DEMO_API_REGION,
          authorizationType: AWS_COGNITO_POOL_AUTH_TYPE,
          custom_header: async () => { 
              return { 
                Accept: "*/*",
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` 
              }
           }
        },
        {
          endpointType: REST,
          name: API_GATEWAY_RESOURCE,
          endpoint: API_ENDPOINT_URL,
          region: AWS_REGION,
          authorizationType: AWS_COGNITO_POOL_AUTH_TYPE,
          custom_header: async () => { 
              return { 
                Accept: "*/*",
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` 
              }
           }
        },
        {
          endpointType: REST,
          name: VIPER_API_GATEWAY,
          endpoint: VIPER_API_LIST_STACK_URL,
          region: AWS_REGION,
          authorizationType: AWS_COGNITO_POOL_AUTH_TYPE,
          custom_header: async () => { 
              return { 
                Accept: "*/*",
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` 
              }
           }
        }
      ]
    }
  };
    /**
        Irrespective of the environment, resetting the cognito pool to point to the PROD.
        This step is needed so that we don't have to create multiple federate profiles for every cognito pool
    
    */
   extendedConfig.aws_user_pools_id = "us-west-2_1DZFO4btH";
   extendedConfig.aws_user_pools_web_client_id = "4kpdhdraesj1di9hdr5a453q1m";
   extendedConfig.oauth.domain = "augversionadpauth-prod.auth.us-west-2.amazoncognito.com";
   extendedConfig.aws_cognito_identity_pool_id = "us-west-2:5359e90d-d84e-4f27-908d-47f8ac346075";

   /**
    *  Disabled 
    */
   if (!window.location.origin.endsWith("amazon.dev")) {
        extendedConfig["Analytics"] = {
            disabled: true
        };
   }

   return extendedConfig;
};