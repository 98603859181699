import React from 'react';
import {API} from 'aws-amplify';
import { DEMO_ERROR_API_NAME, DEMO_PATH } from '../utils/constants';


export const Footer = () => {

  const createSyntaxError = () => {

    eval('test str');

  };

  const  createAPIError = () => {



    API.get(DEMO_ERROR_API_NAME, DEMO_PATH).then(data => {
      console.log(data);
    }).catch(error =>{
      console.error(error);
      console.error(error.message);
    });

  };

  return (
  <footer id={"footer"} className="footercss">
   
    <p className="awsui-util-f-r">
      Brought to life by <a className="cursor-pointer" href="https://w.amazon.com/bin/view/Advertising/ATE/CoreInfrastructure/ADP">ADP tech Data Platform</a> &nbsp;&nbsp; <span>|</span>&nbsp;&nbsp;
      <a className="cursor-pointer" onClick={createSyntaxError}>Create Syntax error</a>
      &nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;
      <a className="cursor-pointer" onClick={createAPIError}>Create API error</a>
    </p>
   
  </footer>);

};
