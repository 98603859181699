import React, {useState} from 'react';
import './App.css';
import { Hub }from "@aws-amplify/core";
import {Amplify, AWSCloudWatchProvider} from '@aws-amplify/core';
import {API} from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import awsExports from './aws-exports';
import { NavBar } from './components/Navbar';
import { UserInfo } from './components/UserInfo';
import { Footer } from './components/Footer';
import { Unlogged } from './components/Unlogged';
import { ViperStackInfo } from './components/ViperStackInfo';
import {Analytics} from '@aws-amplify/analytics';
import { VIPER_API_GATEWAY, VIPER_LIST_STACK_PATH } from './utils/constants';
import { Logger } from 'aws-amplify';
import { getAWSExportConfig } from './utils/getAWSExports';

Amplify.configure(getAWSExportConfig());

if (window.location.origin.endsWith(".amazon.dev")) {
  /** 
   * Enable Analytics only if its PROD
   * */
  const analyticsConfig = {
    appId: awsExports.aws_mobile_analytics_app_id,
    region: awsExports.aws_project_region,
    mandatorySignIn: false
  };
  Analytics.configure(analyticsConfig);
}


export const App = () => {

  const [isLoggedIn, setIsLoggedin] = React.useState(false);
  const [ user, setUser ] = useState(null);
  const [ username, setUsername ] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [stacks, setStacks] = useState(null);
  const logger = new Logger("adp-naws-amplify", "INFO");

  Amplify.register(logger);
  logger.addPluggable(new AWSCloudWatchProvider());


  const getViperStacks = async () => {
    logger.info("Default viper api gateway stack owner: " + username);
    

    API.get(VIPER_API_GATEWAY, VIPER_LIST_STACK_PATH, {
      'queryStringParameters': {
        'stack_owner': username
      }
    }).then(data => {
      logger.info("stack info list length: " + data.stack_info_list.length);
      setStacks(data.stack_info_list);
    }).catch(error =>{
      logger.error("default viper API gateway call failed with an error ", error);
      console.error(error);
    });

    await Analytics.updateEndpoint({
      address: userEmail,
      attributes: {
          viewTeamStack: ['No'],
          viewGitRepo: ['No'],
          viewQuipDoc: ['No'],
          viewRoadMap: ['No']

      },
      channelType: 'EMAIL',
      userAttributes: {
        "username": [username],
        "given_name": [user?.attributes?.given_name],
        "family_name": [user?.attributes?.family_name]
      },
      userId: userEmail,
      optOut: 'NONE'
    });
    await Analytics.record({
      name: 'DefaultViperAPIGatewayCall'
    });

  };

  React.useEffect(() => {
    
    if (username !== null && username.length > 0) {
      logger.info("Fetching stacks from viper");
      getViperStacks();

    }


  }, [username]);
  
  React.useEffect(() => {
    logger.info("Mounting begins");

    let search = window.location.search;
    let params = new URLSearchParams(search);

    Auth.currentAuthenticatedUser()
      .then(user => {

        if (user.hasOwnProperty("attributes")) {
          setUser(user);
          if (user.hasOwnProperty("attributes") && user.attributes.hasOwnProperty("email") && user.attributes.email.length > 0) {
            
            setUsername(user.attributes.email.replace('@amazon.com', ''));
            setUserEmail(user.attributes.email);
          }
          
        }
        
      })
      .catch(error => {
        logger.error(error);
      });



        // Amplify uses Hub for different categories to communicate with one another when specific events occur:
        // such as authentication events like a user sign-in
        // When Auth.federatedSignIn returns, it sends an event to Hub which need to be listened.
        
        Hub.listen("auth", ({ payload: { event, data } }) => {
          switch (event) {
              case "signIn":
                  logger.info("Hub listening to signIn event");
                  setIsLoggedin(true);
                  break;
              case "signIn_failure":
                  logger.error("Hub listening to signIn_failure event");
                  break;
              default:
                  break;
          }
        });
  
        //Call Auth.currentAuthenticatedUser() to get the current authenticated user object.
        //This method is used to check if a user is logged in when the page is loaded.
        //It will throw an error if there is no user logged in.
        //In case of error, i.e. when the user is not logged in, call Auth.federatedSignIn to redirect the user to Midway
        Auth.currentAuthenticatedUser()
            .then((data) => {
                setIsLoggedin(true);
              })
            .catch((err) => {
                //look for code in URL, if code is there, it means user is already authenticated
                if (!params.has("code")) {
                    logger.info("Auth federated sign in event");
                    Auth.federatedSignIn({ customProvider: "MidwayOIDCIdentityProvider" });
                    
                }
            });
  },  []);
if (isLoggedIn && user !== null) {
    return(
      <div className='awsui'>
  
        <NavBar username={user?.attributes?.given_name} userObject={user?.attributes} userEmail={userEmail} />
        <UserInfo userObject={user?.attributes} />
        <ViperStackInfo userObject={user?.attributes} logger={logger} username={user} userEmail={userEmail} stackList={stacks} />
        <Footer />

      </div>
    );
  } else {
    return(
      <Unlogged/>
    );
  }
};


