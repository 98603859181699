import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import '@aws-amplify/ui-react/styles.css';
import { AwsRum } from 'aws-rum-web';

/**
 *  Report AWS RUM events only if its PROD
 */
if (window.location.origin.endsWith("amazon.dev")) {

  try {
    const config = {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::471996358448:role/amplify-adpnawsaugversion-prod-44504-authRole", // "arn:aws:iam::471996358448:role/amplify-adpnawsaugversion-prod-44504-unauthRole"
      identityPoolId: "us-west-2:5359e90d-d84e-4f27-908d-47f8ac346075",
      endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: true
    };
  
    const APPLICATION_ID = '64e0deef-2e79-4694-a3d5-314d0cf302a9';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-west-2';
  
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
  

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a functio
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
