import React from 'react';
import {Analytics} from '@aws-amplify/analytics';
import { defaultMessage } from '../utils/constants';

export const NavBar = ({ username, userObject, userEmail }) => {
  const gitRepoClicked = async () => {
    console.log("##git repo clicked ###");

    if (username === defaultMessage || username === null) return;

   await Analytics.updateEndpoint({
      address: userEmail,
      attributes: {
        viewGitRepo: ['Yes']
      },
      channelType: 'EMAIL',
      userAttributes: {
        "username": [userEmail.replace('@amazon.com', '')],
        "given_name": [userObject?.given_name],
        "family_name": [userObject?.family_name]
      },
      userId: userEmail,
      optOut: 'NONE'
    });

   await Analytics.record({
      name: 'GitRepoViewed',
      immediate: true
    });
  }

  const gitQuipClicked = async () => {
    console.log("quip repo clicked");

    if (username === defaultMessage || username === null) return;

   await Analytics.updateEndpoint({
      address: userEmail,
      attributes: {
        viewQuipDoc: ['Yes']
      },
      channelType: 'EMAIL',
      userAttributes: {
        "username": [userEmail.replace('@amazon.com', '')],
        "given_name": [userObject?.given_name],
        "family_name": [userObject?.family_name]
      },
      userId: userEmail,
      optOut: 'NONE'
    });
    
   await Analytics.record({
      name: 'QuipDesignDocViewed',
      immediate: true
    });
  }

  const roadmapClicked = async () => {
    console.log("## roadmap clicked");

    if (username === defaultMessage || username === null) return;

   await Analytics.updateEndpoint({
      address: userEmail,
      attributes: {
        viewRoadMap: ['Yes']
      },
      channelType: 'EMAIL',
      userAttributes: {
        "username": [userEmail.replace('@amazon.com', '')],
        "given_name": [userObject?.given_name],
        "family_name": [userObject?.family_name]
      },
      userId: userEmail,
      optOut: 'NONE'
    });
    
   await Analytics.record({
      name: 'RoadMapViewed',
      immediate: true
    });
  }
  return (

    <ul id="navbar" className="menu-list">
    <li className="selected"><a href="/">ADP UI with Amplify</a></li>
    <li className="ml-auto font-white"><a href="/">Home</a></li>
    <li className="font-grey">|</li>
      <li><a className="cursor-pointer" href="https://code.amazon.com/packages/Adp-naws-amplify/trees/mainline" target="_blank" onClick={gitRepoClicked} rel="noopener noreferrer">Github Code Repository</a></li>
    <li className="font-grey">|</li>
    <li><a className="cursor-pointer" href="https://quip-amazon.com/QbY4AbIbkW6v/ADP-NAWS-with-Amplify-Architecture-Concepts" target="_blank" onClick={gitQuipClicked} rel="noopener noreferrer">Amplify Deep Dive</a></li>
    <li className="font-grey">|</li>
    <li><a className="cursor-pointer" href="https://quip-amazon.com/cXFFAQt6aWd5/NAWS-ADP-Web-Application-Project-Roadmap" target="_blank" onClick={roadmapClicked} rel="noopener noreferrer">Internship Project Roadmap</a></li>
    
  </ul>
  );
   
};