/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "6cced71b0d7040eda3d2c36920b52683",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "demoErrorAPI",
            "endpoint": "https://clwoyixz08.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:5359e90d-d84e-4f27-908d-47f8ac346075",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1DZFO4btH",
    "aws_user_pools_web_client_id": "4kpdhdraesj1di9hdr5a453q1m",
    "oauth": {
        "domain": "augversionadpauth-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://develop.d1wq82ndeo2uz0.amplifyapp.com/,https://adp-amplify-naws.adp.amazon.dev/,https://www.adp-amplify-naws.adp.amazon.dev/",
        "redirectSignOut": "http://localhost:3000/,https://develop.d1wq82ndeo2uz0.amplifyapp.com/,https://adp-amplify-naws.adp.amazon.dev/,https://www.adp-amplify-naws.adp.amazon.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
