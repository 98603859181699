import React from 'react';
import { Expander, ExpanderItem } from '@aws-amplify/ui-react';
import { Heading } from '@aws-amplify/ui-react';
import { useTheme, Flex, View } from '@aws-amplify/ui-react';
import { VIPER_API_GATEWAY, VIPER_LIST_STACK_PATH } from '../utils/constants';
import {API} from 'aws-amplify';
import {Analytics} from '@aws-amplify/analytics';
import { SearchField } from '@aws-amplify/ui-react';


export const ViperStackInfo = ({stackList, userObject, username, userEmail, logger}) => {

    const [teamStack, setTeamStack] = React.useState(null);
    const [teamUsername, setTeamUsername] = React.useState(null);


    const reloadTeamStack = () => {
        console.log(logger);
        logger.info("Reload team stack");
        getViperStacks();
    }
    const getViperStacks = async () => {
        logger.info("Fetching stack for team member: " + teamUsername);
        console.log("Stack owner" + teamUsername);
        if (teamUsername.length === 0) return;

        API.get(VIPER_API_GATEWAY, VIPER_LIST_STACK_PATH, {
          'queryStringParameters': {
            'stack_owner': teamUsername
          }
        }).then(data => {
          logger.info("Successfully loaded stack for team member: " + teamUsername);
          setTeamStack(data.stack_info_list);
        }).catch(error =>{
          logger.error("Error loading stack for team member: " + teamUsername + " - error " + error);
          console.error(error);
        });

        await Analytics.updateEndpoint({
            address: userEmail,
            attributes: {
                viewTeamStack: ['Yes']
            },
            channelType: 'EMAIL',
            userAttributes: {
              "username": [userEmail.replace('@amazon.com', '')],
              "given_name": [userObject?.given_name],
              "family_name": [userObject?.family_name]
            },
            userId: userEmail,
            optOut: 'NONE'
          });
          await Analytics.record({
            name: 'TeamMemberViperAPIGatewayCall',
            immediate: true
          });

       

      };

     const resetTeam = () => {
        logger.info("Resetting search team member form values");

        setTeamStack(null);
        setTeamUsername(null);
     } 
    

    const { tokens } = useTheme();


    if(stackList === null || stackList === undefined) {
        return (
            <h3 className={'ml-1 color-grey'}>Loading stack information...</h3> 
        );
    } else { 
        return(
            <div className='m-1'>   
                <h3 className='ml-1'> Stacks that you own: </h3>
                {
                    stackList.length === 0 ? <h4 className='ml-1 color-grey'>No Stacks to show!</h4> 
                    :  <Expander type="single" isCollapsible={true}>
        
                    {
                        stackList.map((stack, index) => (
                            <ExpanderItem key={'selfviperStack'+ index} title={<CustomTitle
                                courseNumber={stack.stack_name}
                                courseName={stack.stack_owner}
                              />} value={'viperStack'+ index}>
                                <h5>Description: <b>{stack.stack_description}</b></h5>
                                <h5>Version: <b>{stack.current_stack_version}</b></h5>
                                <h5>Stage: <b>{stack.stack_stage}</b></h5>
                                <h5>Status: <span className='color-green'><b>{stack.stack_status === null ? 'Unkown' : stack.stack_status}</b></span></h5>
                                <h5>Group: <b>{stack.ldap === null ? 'Unkown' : stack.ldap}</b></h5>
    
    
                                
                            </ExpanderItem>
            
                        ))
                    }
                    </Expander>
                }
        
                

                <h3 className='ml-1'> Stacks created by your team members: </h3>
                <div className='m-1'>
                <Flex gap="1rem" direction={"row"}>
                    <SearchField
                        className='ml-1 p-1'
                        placeholder="Enter Username"
                        value={teamUsername === null ? '' : teamUsername}
                        onSubmit={() => reloadTeamStack()}
                        onClear={() => resetTeam()}
                        onChange={(event)=> setTeamUsername(event.target.value)}
                    />

                </Flex>

                </div>
                
                <div className='m-1'>
                {
                    teamStack ===  null || teamUsername === null ? null :
                    teamStack !== null && teamStack.length === 0 && teamUsername !== null ? 
                        <Heading level={6}>No Stacks to show!</Heading> 
                        :
                
                <Expander type="single" isCollapsible={true}>
        
                {
                    teamStack.map((stack, index) => (
                        <ExpanderItem key={'teamviperStack'+ index} title={<CustomTitle
                            courseNumber={stack.stack_name}
                            courseName={stack.stack_owner}
                          />} value={'teamviperStack'+ index}>
                            <h5>Description: <b>{stack.stack_description}</b></h5>
                            <h5>Version: <b>{stack.current_stack_version}</b></h5>
                            <h5>Stage: <b>{stack.stack_stage}</b></h5>
                            <h5>Status: <span className='color-green'><b>{stack.stack_status === null ? 'Unkown' : stack.stack_status}</b></span></h5>
                            <h5>Group: <b>{stack.ldap === null ? 'Unknown' : stack.ldap}</b></h5>


                            
                        </ExpanderItem>
        
                    ))
                }
                </Expander>
            }

                </div>

                

                

                    
                    
            </div>     
            );
        
    }

    


}


export const CustomTitle = ({ courseNumber, courseName }) => {
    const { tokens } = useTheme();
    return (
      <Flex direction="row" justifyContent={"space-between"} width="100%">
        <View color={tokens.colors.teal[80]}>
          {courseNumber}
        </View>
        <View>{courseName}</View>
      </Flex>
    );
  };