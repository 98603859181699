export const API_GATEWAY_RESOURCE = "test-amplify-naws";
export const API_ENDPOINT_URL = "https://rxtuzf5ark.execute-api.us-east-1.amazonaws.com/test";
export const DEMO_STACKS_PATH = "/demostacks";
export const AWS_REGION = "us-east-1";
export const AWS_COGNITO_POOL_AUTH_TYPE = "AMAZON_COGNITO_USER_POOLS";
export const REST = "REST";
export const DEBUG = "DEBUG";

export const VIPER_API_GATEWAY="StackManagerAPI";
export const VIPER_API_LIST_STACK_URL = "https://5d6p0l6vj6.execute-api.us-east-1.amazonaws.com/test";
export const VIPER_LIST_STACK_PATH = "/v2/stack/list/info";
export const defaultMessage = "Logging you in ....";

export const DEMO_ERROR_API_NAME= "demoErrorAPI";
export const DEMO_ERROR_API_URL="https://clwoyixz08.execute-api.us-west-2.amazonaws.com/prod";
export const DEMO_PATH="/createError";
export const DEMO_API_REGION="us-west-2";
