import React from 'react';
import { Collection, Card, Heading, Text, useTheme } from '@aws-amplify/ui-react';

export const UserInfo = ({userObject}) => {
    const { tokens } = useTheme();

    const items = [{
        title: 'Name',
        description: userObject.given_name + " " + userObject.family_name
    }, {
        title: 'Email',
        description: userObject.email
    }];
    return(
    
        <Collection
        type='list'
        direction="row"
        gap="20px"
        wrap="nowrap"
        items={items}
        >
           {(item, index) => (
            <Card
                key={index}
                padding={tokens.space.medium}
                maxWidth="180px"
                fontSize={tokens.fontSizes.large}
            >
                <Heading level={6}>{item.title}</Heading>
                <Text>{item.description}</Text>
            </Card>
            )}

        </Collection>
   
    );

};